import TypeTemplate from 'components/solutionsTemplate/typeTemplate';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const Residential = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSolutionByType(
        filter: { ONLY_FOR_DEV_pageId: { eq: "residential" } }
      ) {
        nodes {
          id
          PageIcon {
            url
          }
          PageLabel
          HeroMainBannerHeader
          HeroMainBannerDescription
          HeroMainBannerCTAButtonLink
          HeroMainBannerCTAButtonLabel
          CustomerCardLogo {
            url
            alternativeText
          }
          CustomerCardTitle
          CustomerCardCTAButtonLabel
          CustomerCardCTAButtonLink
          CustomerCardDescription
          ConstructionDescription
          ConstructionHeader
          BenefitHeader
          BenefitCard {
            description
            header
            id
          }
          KeyCapabilitiesHeader
          KeyCapabilitiesCTAButtonLabel
          KeyCapabilitiesSystem {
            id
            SystemName
            SystemCTAButtonLink
            Description
          }
          ExampleCard {
            Caption
            Image {
              url
            }
            id
          }
          ExampleHeader
        }
      }
    }
  `);
  return <TypeTemplate data={data} pageName="residential" />;
};

export default Residential;
